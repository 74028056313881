import styled, { css } from "styled-components";

export const InputWrapper = styled.div`
  line-height: 1;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
`;

export const InputField = styled.input<{ readonly hasError?: boolean }>`
  color: #000;
  background-color: #fff;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  padding: 0 1.35rem;
  height: 4.1rem;
  max-height: 4.1rem;

  ${({ hasError }) =>
    !hasError
      ? css`
          border: solid 0.1rem #dededf;
          &:hover {
            border-color: #dededf;
          }
          &:focus {
            border-color: #000;
          }
          &::placeholder {
            font-size: 1.4rem;
            color: #b5b5b5;
          }
          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        `
      : css`
          border: solid 0.1rem #e60000;
        `}
`;

export const PasswordShow = styled.div`
  position: absolute;
  right: 2rem;
  top: 3.5rem;
  user-select: none;
  cursor: pointer;
`;

export const Error = styled.div`
  color: #e60000;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 1.2rem;
`;
