const GoogleLogin = () => (
  <svg
    data-name="icons8-google 1"
    xmlns="http://www.w3.org/2000/svg"
    width="28.963"
    height="28.963"
    viewBox="0 0 28.963 28.963"
  >
    <path
      d="M28.682 11.645h-1.167v-.06H14.482v5.793h8.184a8.7 8.7 0 1 1-2.42-9.385l4.1-4.1a14.467 14.467 0 1 0 4.621 10.585 14.58 14.58 0 0 0-.285-2.833z"
      style={{ fill: "#ffc107" }}
    />
    <path
      data-name="Vector"
      d="m0 7.741 4.758 3.489a8.671 8.671 0 0 1 13.818-3.237l4.1-4.1A14.453 14.453 0 0 0 0 7.741z"
      transform="translate(1.67)"
      style={{ fill: "#ff3d00" }}
    />
    <path
      data-name="Vector"
      d="M12.892 11.546A14.414 14.414 0 0 0 22.6 7.787l-4.481-3.793a8.624 8.624 0 0 1-5.227 1.76A8.685 8.685 0 0 1 4.722 0L0 3.639a14.47 14.47 0 0 0 12.892 7.907z"
      transform="translate(1.589 17.417)"
      style={{ fill: "#4caf50" }}
    />
    <path
      data-name="Vector"
      d="M14.2.06h-1.167V0H0v5.793h8.184a8.718 8.718 0 0 1-2.959 4.033l4.482 3.793A14.009 14.009 0 0 0 14.482 2.9 14.58 14.58 0 0 0 14.2.06z"
      transform="translate(14.482 11.585)"
      style={{ fill: "#1976d2" }}
    />
  </svg>
);

export default GoogleLogin;
