const Invisible = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.726" height="13.381" viewBox="0 0 16.726 13.381">
    <path
      data-name="Icon awesome-eye-slash"
      d="M8.363 10.454a3.749 3.749 0 0 1-3.735-3.479L1.887 4.856a8.71 8.71 0 0 0-.96 1.453.845.845 0 0 0 0 .763 8.382 8.382 0 0 0 7.436 4.636 8.118 8.118 0 0 0 2.036-.273l-1.357-1.05a3.767 3.767 0 0 1-.679.068zm8.2 1.518-2.888-2.233A8.657 8.657 0 0 0 15.8 7.072a.845.845 0 0 0 0-.763 8.382 8.382 0 0 0-7.437-4.637 8.053 8.053 0 0 0-3.85.985L1.188.088A.418.418 0 0 0 .6.161L.088.822a.418.418 0 0 0 .073.587l15.377 11.883a.418.418 0 0 0 .587-.073l.513-.66a.418.418 0 0 0-.074-.587zm-4.8-3.711-1.027-.794A2.476 2.476 0 0 0 7.7 4.28a1.245 1.245 0 0 1 .243.737 1.219 1.219 0 0 1-.04.261L5.981 3.792a3.719 3.719 0 0 1 2.382-.865 3.761 3.761 0 0 1 3.763 3.763 3.674 3.674 0 0 1-.363 1.571z"
      style={{ fill: "#e0e0e0" }}
    />
  </svg>
);

export default Invisible;
