import FacebookLoginIcon from "@Components/NEW_TS/icons/FacebookLogin";
import GoogleLoginIcon from "@Components/NEW_TS/icons/GoogleLogin";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import useTranslation from "next-translate/useTranslation";
import { Dispatch, SetStateAction } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import handleSocialLogin from "../utils/handleSocialLogin";
import { SocialLogin, SocialLoginBtn, SocialLoginText } from "./Auth.styled";

interface Props {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSuccess?: Dispatch<SetStateAction<boolean>>;
  setUserData: Dispatch<SetStateAction<any>>;
  updateToken: (token: string) => void;
  actionType: string;
  fbqEvent: string;
}
const SocialLoginView = ({ setIsLoading, setSuccess, setUserData, updateToken, actionType, fbqEvent }: Props) => {
  const { t } = useTranslation("common");

  return (
    <SocialLogin>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <CustomGoogleLogin
          setIsLoading={setIsLoading}
          setSuccess={setSuccess}
          setUserData={setUserData}
          updateToken={updateToken}
          actionType={actionType}
          fbqEvent={fbqEvent}
        />
      </GoogleOAuthProvider>
      <FacebookLogin
        appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email"
        callback={response => {
          handleSocialLogin({
            response,
            setIsLoading,
            setSuccess,
            setUserData,
            updateToken,
            authType: "facebook",
            actionType,
            fbqEvent
          });
        }}
        render={renderProps => (
          <SocialLoginBtn onClick={renderProps.onClick}>
            <FacebookLoginIcon />
            <SocialLoginText>{t("continue-with-facebook")}</SocialLoginText>
          </SocialLoginBtn>
        )}
      />
    </SocialLogin>
  );
};

export default SocialLoginView;

function CustomGoogleLogin({ setIsLoading, setSuccess, setUserData, updateToken, actionType, fbqEvent }) {
  const { t } = useTranslation("common");

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        method: "POST"
      }).then(res => res.json());

      handleSocialLogin({
        response,
        setIsLoading,
        setSuccess,
        setUserData,
        updateToken,
        authType: "google",
        actionType,
        fbqEvent
      });
    }
  });

  return (
    <SocialLoginBtn onClick={() => login()}>
      <GoogleLoginIcon />
      <SocialLoginText>{t("continue-with-google")}</SocialLoginText>
    </SocialLoginBtn>
  );
}
