import { Dispatch, SetStateAction } from "react";

import * as fbq from "@Services/FacebookPixel";
import { GTagEvent, logGEvent } from "lib/gtag";

import Toast from "@Components/NEW_TS/common/Toast/index";

import { socialLoginHandler } from "@Services/User";

const analytics: (actionType: string, authType: string) => GTagEvent = (actionType, authType) => ({
  action: `click-${actionType}`,
  category: authType,
  label: `${authType} login`,
  value: 1
});

interface Params {
  response: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSuccess?: Dispatch<SetStateAction<boolean>>;
  setUserData: Dispatch<SetStateAction<any>>;
  updateToken: (token: string) => void;
  authType: "google" | "facebook";
  actionType: string;
  fbqEvent: string;
}

const handleSocialLogin = ({
  response,
  setIsLoading,
  setSuccess,
  setUserData,
  updateToken,
  authType,
  actionType,
  fbqEvent
}: Params) => {
  const formData =
    authType === "facebook"
      ? {
          social_type: "facebook",
          user_id: response?.userID,
          id: response?.id,
          email: response?.email || "",
          name: response?.name || ""
        }
      : {
          social_type: "google",
          email: response?.email,
          id_token: response?.sub,
          first_name: response?.given_name,
          last_name: response?.family_name
        };

  if (response.status !== "unknown") {
    setIsLoading(true);
    socialLoginHandler(formData)
      .then(({ data }) => {
        setSuccess && setSuccess(true);
        logGEvent(analytics(actionType, authType));
        fbq.event(fbqEvent);
        setUserData(data?.data.user);
        updateToken(data?.data.token);
      })
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        Toast({
          type: "error",
          body: data?.message || `Error while login with ${authType}`
        });
      });
  }
};

export default handleSocialLogin;
