import styled from "styled-components";

import { stripButton } from "@Utils/style-util";

export const AuthWrapper = styled.div`
  display: flex;
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  line-height: 1;
  @media (min-width: 767.98px) {
    width: 70%;
    margin-left: auto;
  }
  @media (min-width: 991.98px) {
    width: 50%;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  max-width: unset;
  padding: 3.02rem 2rem 7.2rem;
  @media (min-width: 767.98px) {
    max-width: 50rem;
    padding: 4.35rem 2rem 4.35rem;
  }
`;

export const PageTitle = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.17px;
  color: #000;
  margin-bottom: 2.75rem;
  @media (min-width: 767.98px) {
    font-size: 2.4rem;
    letter-spacing: 0.14px;
    margin-bottom: 4.15rem;
  }
`;

export const LoginKink = styled.div`
  margin-top: 2.6rem;
  display: flex;
  gap: 0.88rem;
  font-size: 1.5rem;
`;

export const LoginLinkDesc = styled.div`
  font-weight: 400;
  color: #000;
`;

export const LoginLinkBtn = styled.button`
  ${stripButton}
  font-weight: 600;
  color: #f06369;
`;

export const Banner = styled.div`
  position: sticky;
  height: calc(100vh - 81px);
  top: 8.1rem;
  @media (min-width: 767.98px) {
    width: 30%;
  }
  @media (min-width: 991.98px) {
    width: 50%;
  }
`;

export const SocialLogin = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  @media (min-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3.2rem;
  }
`;

export const SocialLoginBtn = styled.button`
  ${stripButton}
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.02rem;
  border-radius: 0.3rem;
  padding: 1.05rem 0;
  border: solid 1px #dbdbdb;
  @media (min-width: 767.98px) {
    gap: 1.43rem;
  }
`;

export const SocialLoginText = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #215fd8;
`;

export const Separator = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #a8a8a8;
  position: relative;
  margin: 2.81rem 0 3.8rem;
  @media (min-width: 767.98px) {
    margin: 2.62rem 0 4.09rem;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: #dededf;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: #dededf;
  }
`;
