import { Main } from "@Components/common";
import Footer from "@Components/NEW_TS/Footer";
import Navbar from "@Components/NEW_TS/Navbar";
import NEW_TS_Theme from "@Style/NEW_TS/Theme";
import { NextSeo, ProductJsonLd } from "next-seo";
import Head from "next/head";
import { Fragment } from "react";
import { ThemeProvider } from "styled-components";

const Layout = ({ children, seoData, noFollow = false, product = null }) => {
  return (
    <Fragment>
      <NextSeo {...seoData} {...(noFollow && { nofollow: true, noindex: true })} />
      {product && (
        <Fragment>
          <Head>
            <meta property="product:price:amount" content={product.price} />
            <meta property="product:price:currency" content="EGP" />
            <meta property="og:price:amount" content={product.price} />
            <meta property="og:price:currency" content="EGP" />
          </Head>
          <ProductJsonLd
            productName={product.title}
            images={product.images || [product.image_path]}
            description={product.summary}
            brand={product.brand.name}
          />
        </Fragment>
      )}

      <ThemeProvider theme={NEW_TS_Theme}>
        <Navbar />
        <Main>{children}</Main>
        <Footer />
      </ThemeProvider>
    </Fragment>
  );
};

export default Layout;
