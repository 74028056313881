const FacebookLogin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.159" height="28.159" viewBox="0 0 28.159 28.159">
    <path
      data-name="Path 35372"
      d="M19.08 5a14.08 14.08 0 1 0 14.08 14.08A14.08 14.08 0 0 0 19.08 5z"
      style={{ fill: "#039be5" }}
      transform="translate(-5 -5)"
    />
    <path
      data-name="Path 35373"
      d="M23.953 24.832H27.6l.572-3.7h-4.22v-2.025c0-1.538.5-2.9 1.941-2.9H28.2v-3.23a19.633 19.633 0 0 0-2.888-.175c-3.389 0-5.375 1.79-5.375 5.867v2.461h-3.48v3.7h3.484v10.175a12.767 12.767 0 0 0 4.012.03z"
      transform="translate(-7.967 -7.02)"
      style={{ fill: "#fff" }}
    />
  </svg>
);

export default FacebookLogin;
