const Visible = () => (
  <svg
    data-name="Icon ionic-ios-eye"
    xmlns="http://www.w3.org/2000/svg"
    width="17.603"
    height="11.315"
    viewBox="0 0 17.603 11.315"
  >
    <path
      data-name="Path 35245"
      d="M11.044 7.875c-3.159 0-5.65 1.988-8.628 5.237a.621.621 0 0 0 0 .837c2.55 2.817 4.8 5.241 8.632 5.241 3.788 0 6.628-3.053 8.648-5.265a.616.616 0 0 0 .02-.813c-2.064-2.495-4.916-5.237-8.672-5.237zm.173 9.19a3.536 3.536 0 1 1 3.367-3.365 3.538 3.538 0 0 1-3.367 3.365z"
      transform="translate(-2.252 -7.875)"
      style={{ fill: "#f06369" }}
    />
    <path
      data-name="Path 35246"
      d="M16.015 14.168a1.846 1.846 0 0 1 .122-.664h-.122a2.514 2.514 0 1 0 2.515 2.515v-.153a1.742 1.742 0 0 1-.715.153 1.824 1.824 0 0 1-1.8-1.851z"
      transform="translate(-7.215 -10.357)"
      style={{ fill: "#f06369" }}
    />
  </svg>
);

export default Visible;
