import { useEffect, useState } from "react";

import { Validate, FieldError } from "react-hook-form";

import Visible from "@Components/NEW_TS/icons/Visible";
import Invisible from "@Components/NEW_TS/icons/Invisible";

import { InputWrapper, InputGroup, Label, InputField, PasswordShow, Error } from "./Input.styled";

interface Props {
  register;
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  required?: {
    value: boolean;
    errorMessage?: string;
  };
  min?: {
    value: number;
    errorMessage?: string;
  };
  max?: {
    value: number;
    errorMessage?: string;
  };
  minLength?: {
    value: number;
    errorMessage?: string;
  };
  maxLength?: {
    value: number;
    errorMessage?: string;
  };
  pattern?: {
    value: RegExp;
    errorMessage?: string;
  };
  validate?: Validate<any> | Record<string, Validate<any>>;
  errors?: FieldError;
  isDisabled?: boolean;
}
const Input = ({
  register,
  name,
  type,
  label,
  placeholder,
  value,
  defaultValue,
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  errors,
  isDisabled
}: Props) => {
  // To prevent input type number from increasing/decreasing upon mouse scroll on focus on that input
  useEffect(() => {
    const inputElement = document.getElementById(name);
    const handleInputWheel = () => {
      inputElement.blur();
    };
    inputElement.addEventListener("mousewheel", handleInputWheel);
    return () => {
      inputElement.removeEventListener("mousewheel", handleInputWheel);
    };
  }, []);

  // Toggle type between text and password
  const [textPassword, reverseTextPassword] = useState(true);

  return (
    <InputWrapper>
      <InputGroup>
        <Label htmlFor={name}>{label}</Label>
        <InputField
          type={type !== "password" ? type : type === "password" && textPassword ? "password" : "text"}
          {...(register &&
            register(name, {
              value,
              required: required && {
                value: required.value,
                message: required.errorMessage
              },
              min: min && {
                value: min.value,
                message: min.errorMessage
              },
              max: max && {
                value: max.value,
                message: max.errorMessage
              },
              minLength: minLength && {
                value: minLength.value,
                message: minLength.errorMessage
              },
              maxLength: maxLength && {
                value: maxLength.value,
                message: maxLength.errorMessage
              },
              pattern: pattern && {
                value: pattern.value,
                message: pattern.errorMessage
              },
              validate: validate && validate
            }))}
          id={name}
          disabled={isDisabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          hasError={Boolean(errors)}
        />
        {type === "password" && (
          <PasswordShow onClick={() => reverseTextPassword(prevState => !prevState)}>
            {textPassword ? <Invisible /> : <Visible />}
          </PasswordShow>
        )}
      </InputGroup>
      {errors && <Error>{errors.message}</Error>}
    </InputWrapper>
  );
};

export default Input;
