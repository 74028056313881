import { StyledSubmitBtn } from "./SubmitBtn.styled";

import Loading from "@Components/Loading";

const SubmitBtn = ({ isLoading, submitText }) => {
  return (
    <StyledSubmitBtn isLoading={isLoading} type="submit" disabled={isLoading}>
      {isLoading ? <Loading color="#fff" spinner={true} size="30" /> : submitText}
    </StyledSubmitBtn>
  );
};

export default SubmitBtn;
