import styled, { css } from "styled-components";

import { stripButton } from "@Utils/style-util";

export const StyledSubmitBtn = styled.button<{
  readonly isLoading?: boolean;
}>`
  ${stripButton}
  padding:0 17px;
  width: 100%;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  @media (min-width: 767.98px) {
    margin-top: 0;
  }
  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
    `}
`;
